<!--
 * @Author: your name
 * @Date: 2021-03-11 15:35:02
 * @LastEditTime: 2021-03-11 16:27:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\components\prompt\Prompt.vue
-->
<template>
  <div>
    <div v-show="typeStaus" class="popup-prompt text-center">
      <template v-if="newStaus">
        <div>{{ pbrq }}{{ sxwbzmc }}</div>
        <br>
        <div>{{ toastContext }}</div>
      </template>
      <template v-else>
        <pre>{{ toastContext }}</pre>
      </template>
      <div class="colse" @click="colse">
        x
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    newStaus:{
      type:Boolean,
      default:true
    },
    typeStaus:{
      type:Boolean,
      default:false
    },
    sxwbzmc:{
      type:String,
      default:""
    },
    pbrq:{
      type:String,
      default:""
    },
    toastContext:{
      type:String,
      default:""
    }
  },
  methods:{
    colse(){

      this.$emit('closePopup')
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
</style>
