<!--
 * @Author: your name
 * @Date: 2021-03-03 16:04:22
 * @LastEditTime: 2023-07-31 11:03:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\appointment\index.vue
-->
<template>
  <div class="appointment">
    <div class="payment-tips-top">
      <span>当前平台仅支持自费就诊，医保暂未开通，无法支付。</span>
    </div>
    <van-form
      colon
      label-width="100"
      input-align="right"
      validate-trigger="onChange"
      @submit="onSubmit"
    >
      <!--      <patient-select @onConfirm="onConfirm"/>-->

      <van-field class="star" :value="patientName" is-link label="就诊人信息" name="patxm" readonly placeholder="选择就诊人" @click="showPicker" />
      <van-field
        v-model="cardName"
        is-link
        class="star"
        label="就诊卡 "
        name="cardName"
        readonly
        placeholder="请选择就诊卡号类型"
        :rules="[{ required: true}]"
        @click="Picker2 = true"
      />
      <van-popup v-model="Picker2" position="bottom">
        <van-picker
          show-toolbar
          :columns="checkTypeList"
          @confirm="checkConfirm"
          @cancel="Picker2 = false"
        />
      </van-popup>
      <div class="fromTtem">
        <div>就诊类型</div>
        <div>{{ hylxmc }}</div>
      </div>
      <div class="fromTtem">
        <div>科室</div>
        <div>{{ ksmc }}</div>
      </div>
      <div class="fromTtem">
        <div>就诊日期</div>
        <div>{{ pbrq }}{{ sxwbzmc }}</div>
      </div>
      <div class="fromTtem fromTtem__tips">
        <div>挂号费</div>
        <div class="right">
          <div class="price">
            ¥{{ ghf }}
          </div>
          <div class="message">
            请于就诊当日进行支付
          </div>
        </div>
        <div class="payment-tips">
          <span>当前平台仅支持自费就诊，医保暂未开通，无法支付</span>
        </div>
      </div>

      <div v-for="(item,idx) in pushList" :key="idx" class="fromTtem">
        <div>
          <span v-show="idx===0" class="star">确诊疾病</span>
        </div>
        <div v-if="showIlless" class="choose">
          <span class="add">暂无疾病</span>
        </div>
        <div v-else-if="!showIlless&&illList.length==1" class="choose">
          <span class="add">{{ item.zdmc }}</span>
        </div>
        <div v-if="illList.length>1" class="choose" @click="addValue(idx)">
          <span class="add">{{ item.zdmc }}</span>
          <img
            v-show="idx===0"
            class="img"
            :src="require('../../assets/img/add.png')"
            @click.stop="add"
          >
          <img
            v-show="idx>0"
            class="img"
            :src="require('../../assets/img/dele.png')"
            @click.stop="delet(idx)"
          >
        </div>
      </div>
      <!-- <DiseaseItem :pushList='pushList' @delet='delet'/> -->
      <label class="form-label star">病情主诉 :</label>
      <van-field
        v-model="remark"
        name="remark"
        rows="5"
        autosize
        :rules="[{ required: true}]"
        input-align="left"
        type="textarea"
        maxlength="200"
        placeholder="为了让医生更清楚的知道您的病情，请尽可能详细描述"
        show-word-limit
      />
      <div class="uppic">
        <div class="uptitle">
          上传检查检验报告和患处照片（最多9张）
        </div>
        <van-uploader
          v-model="fileList"
          multiple
          :max-size="10 * 1024 * 1024"
          max-count="9"
          preview-size="106"
        />
      </div>
      <div class="bottom-check">
        <van-checkbox v-model="checked" class="" checked-color="#3377FE">
          我已仔细阅读并同意<span style="color: #3377fe" @click="gotoConsent">《互联网诊疗知情同意书》</span>
        </van-checkbox>
      </div>
      <div class="footBtn">
        <van-button round size="small" type="info" class="btn" native-type="submit" :loading="buttonLoading" :disabled="disabled">
          提交
        </van-button>
      </div>
    </van-form>
    <van-popup v-model="show" position="bottom" lock-scroll :style="{ height:'50%',width:'100%'}">
      <!--      <van-sticky style="width:100%;">-->
      <!--        <div class="search-title">-->
      <!--          <van-search v-model="zdmcOrsp" placeholder="请输入搜索关键词" @search="onSearch" @input="onSearch" />-->
      <!--        </div>-->
      <!--      </van-sticky>-->
      <div class="search-content">
        <!--        <van-list v-model="loading" :finished="finished" :finished-text="total > count ? '没有更多了' : ''" offset="30"-->
        <!--                  @load="onLoad"-->
        <!--        >-->
        <!--          <van-cell v-for="(item,i) in illList" :key="i" :title="item.zdmc" @click="chooseItem(item)" />-->
        <!--        </van-list>-->
        <van-cell v-for="(item,i) in illList" :key="i" :title="item.zdmc" @click="chooseItem(item)" />
      </div>
    </van-popup>
    <Prompt :type-staus="typeStaus" :pbrq="pbrq" :sxwbzmc="sxwbzmc" :new-staus="newStaus" :toast-context="toastContext"
            @closePopup="closePopup"
    />
  </div>
</template>
<script>
//import DiseaseItem from '../../components/category/diseaseItem'
import Prompt from '../../components/prompt/Prompt'
import {appointModel} from '../../api/appoint'
import {constantModel} from '../../api/constant'
import { mapGetters} from 'vuex'
import {isEmptyObject} from '@/utils/index'
// import {getItem, removeItem} from "@/utils/cache"
export default {
  components: {
    Prompt
  },
  data() {
    return {
      patient: {patxm: ''},
      show: false,
      value: '',
      Picker2: false,
      showIlless:false,
      newshowIlless:false,
      index: 0,
      loading: false,
      disabled:false,
      buttonLoading:false,
      finished: false,
      checkTypeList: [],
      patientList: {},
      typeStaus: false,
      newStaus: true,
      toastContext: '',
      cardName: '',
      hylxmc: '',
      ksmc: '',
      ksdm:'',
      pbrq: '',
      sxwbzmc: '',
      jzkh: '',//就诊卡号
      jzklx: '',//就诊卡号类型
      ghf: '',
      id: '',
      remark: '',
      form: {
        userPatientId: '', //就诊人id
        cardNumber: '',
        diseaseIds: [], //疾病
        remark: '',
        fileList: []//图片
      },
      fileList: [],
      pushList: [{}],
      chooseId: '',
      zdmcOrsp: '',
      illList: [],
      total: 0,
      count: 10,
      page: 1,
      pageSzie: 10,
      checked:null
    }
  },
  computed: {
    patientName() {
      // console.log('patient change', this.patientList)
      const {zjh}=this.patientList
      if(zjh){
        this.getIllList(zjh,this.ksdm)
      }
      return this.patientList ? this.patientList.patxm : ''
    },
    ...mapGetters({choosePatientList:'patient/getChoosePatient'})
  },
  mounted() {
    const {hylx, ksmc, pbrq, sxwbzmc, ghf, id,ksdm} = this.$route.query
    this.hylxmc = hylx
    this.ksmc = ksmc
    this.pbrq = pbrq
    this.sxwbzmc = sxwbzmc
    this.ghf = ghf
    this.id = id
    this.ksdm=ksdm
    //this.getIllList()
    // console.log(this.choosePatientList,'_____++++++')
    this.onConfirm()
    // this.patient = getItem('choosePatient', {})
    // removeItem('choosePatient')
    // console.log('mounted')
  },
  methods: {
    showPicker() {
      this.$router.push({
        path: '/patient',
        query: {
          appoint: true
        }
      })
    },
    closePopup() {
      this.typeStaus = false
    },
    onLoad() {
      this.loading = true
      this.page += 1
      //this.getIllList()
    },
    chooseItem(item) {

      this.pushList.map((v, index) => {
        if (index == this.chooseId) {
          v.zdbm = item.zddm
          v.zdmc = item.zdmc
        }
      })
      this.show = false
    },
    onSearch() {
      this.illList = []
      this.page = 1
      this.total = 0
      this.getIllList()
    },
    getIllList(zjh,ksdm) {
      // let params = {
      //   page: this.page,
      //   pageSzie: this.pageSzie,
      //   zdmcOrsp: this.zdmcOrsp
      //
      // }
      // constantModel.consultill(params).then(res => {
      //   console.dir(res)
      //   this.illList.push(...res.data)
      //   this.loading = false
      //   this.total = res.count
      // })
      let params={
        zjhm:zjh,
        deptCode:ksdm
      }
      constantModel.patientIll(params).then(res => {

        if(res.data.length==0){
          this.showIlless=true
          this.typeStaus = true
          this.newStaus = false
          this.disabled=true
          this.toastContext = '不符合在线复诊条件，请前往线下医院就诊！\n 不满足的原因可能是：\n 1、2个月内未在本院就诊\n 2、该疾病未有初诊记录'

        }else{
          this.newshowIlless=true
          if(res.data.length>1){
            this.newshowIlless=false
            // console.log(this.newshowIlless,'newshowK')
          }
          this.pushList=[{
           zdbm :res.data[0].zddm,
            zdmc:res.data[0].zdmc
          }]

        }
        this.illList.push(...res.data)
        // console.log(this.showIlless,'illlLIST')

      })
    },
    onConfirm() {
      // console.log(isEmptyObject(this.choosePatientList), "选择")

      if (!isEmptyObject(this.choosePatientList)) {
        this.patientList=this.choosePatientList
        appointModel.patientCard({id: this.choosePatientList.id}).then(res => {
          const data = res.data.visitcardinfos
          if (data) {
            let arr = []
            for (let i = 0; i < data.length; i++) {
              let obj = {}
              obj.text = data[i].jzklxmc,
                obj.jzklx = data[i].jzklx,
                obj.jzkh = data[i].jzkh
              arr.push(obj)
            }
            this.checkTypeList = arr
          }
        })
      }


    },
    add() {
      if (this.pushList.length == this.illList.length) {
        this.$toast('最多选择这些!')
        return false
      }
      // if(isEmptyObject(this.pushList[0])){
      //   this.$toast('请选择疾病')
      // }else{
      //   this.pushList.push({})
      // }
      this.pushList.push({})
      // console.log(this.pushList,"添加")
    },

    addValue(index) {
      // console.log(index,'index')
      this.chooseId = index
      this.show = true
    },
    delet(index) {
      this.pushList.splice(index, 1)
    },
    checkConfirm(val) {
      this.jzklx = val.jzklx
      this.jzkh = val.jzkh
      this.cardName = val.text
      this.Picker2 = false
    },
    async upload() {
      this.buttonLoading=true
      for (let i = 0; i < this.fileList.length; i++) {
        const {file} = this.fileList[i]
        const {data} = await constantModel.upImage(file)
         this.form.fileList.push(data && data.accessUrl)
      }

    },
    async submit() {
       if (!this.checked) {
        this.$toast("请先勾选互联网诊疗知情同意书！")
        return false
      }
      if(isEmptyObject(this.pushList[0])){
        this.$toast('请选择疾病')
      }else{
        let params = {
          bqzs: this.remark,
          diagnosis: this.pushList,
          ghf: this.ghf,
          hyid: this.id,
          jzklx: this.jzklx,
          jzrkh: this.jzkh,
          jzrxm: this.patientList.patxm ? this.patientList.patxm : this.patientList.text,
          zjhm: this.patientList.zjh,
          zjlx: this.patientList.zjlx,
          pictures:this.form.fileList
        }
        appointModel.appoint(params).then(res => {
          this.$router.push({
            path: '/appointment/detail',
            query: {
              id: res.data.appointId
            }
          })
        })
          .catch(e => {
            if (e.code === 111101) {
              this.typeStaus = true
              this.toastContext = ' 已有该医生的预约记录，不得重复预约！'
            } else if (e.code === 111108) {
              this.typeStaus = true
              this.toastContext = '当前时段号源无余量，请预约其他日期！'
            } else if (e.code === 111109) {
              this.typeStaus = true
              this.toastContext = '当前号源不在预约时间范围，请预约其他日期！'
            } else if (e.code === 111110) {
              this.typeStaus = true
              this.toastContext = e.data.message.replace(/↵/g, '\n')
            } else if (e.code === 111111) {
              this.typeStaus = true
              this.newStaus = false
              this.toastContext = '不符合在线复诊条件，请前往线下医院就诊！\n 不满足的原因可能是：\n 1、2个月内未在本院就诊\n 2、该疾病未有初诊记录'
            }
          })
      }

    },
    async onSubmit(value) {
      this.form.fileList=[]
      await this.upload()
      this.buttonLoading=false
      // console.log(this.form.fileList,"图片文字")
      await this.submit(value)
    },
    gotoConsent() {
      this.$router.push("/agreeLetter")
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
.payment-tips-top{
  font-size: 26px;
  text-align: center;
  width: 686px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 12px auto 0;
  border-radius: 12px;
  color: #f56c6c;
  background: #fef0f0;
  line-height: 50px;
  height: 50px;
  border: 1px solid #ffe6e6;
}
.payment-tips{
  text-align: right;
  width: 686px;
  box-sizing: border-box;
  margin: 12px auto 0;
  border-radius: 12px;
  color: #f56c6c;
  font-size: 24px;
}
.star:before{
  content: '* ';
  color: red;
  position: absolute;
  left: 7px
}
/deep/ .cellStyle {
  background: none;
}

/deep/ .van-cell__title,
.van-cell__value,
.van-cell__right-icon {
  color: inherit;
  font-size: 28px;
}

/deep/ .van-cell__value {
  color: #969799;
}

/deep/ .van-cell__right-icon {
  color: #969799;
}

/deep/ .van-cell {
  padding: 32px;
}

.appointment {
  height: 100%;
  overflow: scroll;
  font-size: @font-size-28;

  .appoint-input {
    padding: 32px 32px;
    display: flex;
    align-items: center;
  }

  .fromTtem {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 32px 32px;
    border-bottom: 1px solid #eeeeee;
    font-size: @font-size-28;
    color: @dark-font-color;
    background: #fff;
    &.fromTtem__tips{
      padding-bottom: 10px;
    }
    .choose {
      display: flex;
      align-items:center ;


      .img {
        margin: 0 auto;
        width: 24px;
        height: 24px;
      }

      .add {
        margin-right: 10px;
      }
    }

    .right {
      text-align: right;

      .price {
        color: @color-E23E23;
      }

      .message {
        margin-top: 11px;
        color: @light-font-color;
        font-size: @font-size-24;
      }
    }
  }

  .form-label {
    display: block;
    padding: 32px 32px 0;
    color: #646566;
    font-size: @font-size-28;
    background: @white-color;
    margin-top: 16px;
  }

  .uppic {
    font-size: @font-size-30;
    color: @dark-font-color;
    background: @white-color;
    padding: 32px 32px;
    .uptitle {
      margin-bottom: 32px;
    }
  }

  .bottom-check{
    margin:32px;
    font-size: 28px;
    font-weight: 400;
    color: #80848E;
    line-height: 40px;
    padding-bottom: 190px;
  }

  .footBtn {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 96px;
    line-height: 75px;
    text-align: center;
    background-color: @white-color;

    .btn {
      width: 90%;
      height: 68px;
    }
  }

  .search-content {
    height: 80%;
    overflow-y: scroll;

  }
}
</style>
